@charset "UTF-8";
/*! normalize-scss | MIT/GPLv2 License | bit.ly/normalize-scss */
/* Document
       ========================================================================== */
/**
     * 1. Correct the line height in all browsers.
     * 2. Prevent adjustments of font size after orientation changes in
     *    IE on Windows Phone and in iOS.
     */
html {
	line-height: 1.15;
	/* 1 */
	-ms-text-size-adjust: 100%;
	/* 2 */
	-webkit-text-size-adjust: 100%;
	/* 2 */
}

/* Sections
       ========================================================================== */
/**
     * Remove the margin in all browsers (opinionated).
     */
body {
	margin: 0;
}

/**
     * Add the correct display in IE 9-.
     */
article,
aside,
footer,
header,
nav,
section {
	display: block;
}

/**
     * Correct the font size and margin on `h1` elements within `section` and
     * `article` contexts in Chrome, Firefox, and Safari.
     */
h1 {
	font-size: 2em;
	margin: 0.67em 0;
}

/* Grouping content
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
figcaption,
figure {
	display: block;
}

/**
     * Add the correct margin in IE 8.
     */
figure {
	margin: 1em 40px;
}

/**
     * 1. Add the correct box sizing in Firefox.
     * 2. Show the overflow in Edge and IE.
     */
hr {
	box-sizing: content-box;
	/* 1 */
	height: 0;
	/* 1 */
	overflow: visible;
	/* 2 */
}

/**
     * Add the correct display in IE.
     */
main {
	display: block;
}

/**
     * 1. Correct the inheritance and scaling of font size in all browsers.
     * 2. Correct the odd `em` font sizing in all browsers.
     */
pre {
	font-family: monospace, monospace;
	/* 1 */
	font-size: 1em;
	/* 2 */
}

/* Links
       ========================================================================== */
/**
     * 1. Remove the gray background on active links in IE 10.
     * 2. Remove gaps in links underline in iOS 8+ and Safari 8+.
     */
a {
	background-color: transparent;
	/* 1 */
	-webkit-text-decoration-skip: objects;
	/* 2 */
}

/* Text-level semantics
       ========================================================================== */
/**
     * 1. Remove the bottom border in Chrome 57- and Firefox 39-.
     * 2. Add the correct text decoration in Chrome, Edge, IE, Opera, and Safari.
     */
abbr[title] {
	border-bottom: none;
	/* 1 */
	text-decoration: underline;
	/* 2 */
	-webkit-text-decoration: underline dotted;
	text-decoration: underline dotted;
	/* 2 */
}

/**
     * Prevent the duplicate application of `bolder` by the next rule in Safari 6.
     */
b,
strong {
	font-weight: inherit;
}

/**
     * Add the correct font weight in Chrome, Edge, and Safari.
     */
b,
strong {
	font-weight: bolder;
}

/**
     * 1. Correct the inheritance and scaling of font size in all browsers.
     * 2. Correct the odd `em` font sizing in all browsers.
     */
code,
kbd,
samp {
	font-family: monospace, monospace;
	/* 1 */
	font-size: 1em;
	/* 2 */
}

/**
     * Add the correct font style in Android 4.3-.
     */
dfn {
	font-style: italic;
}

/**
     * Add the correct background and color in IE 9-.
     */
mark {
	background-color: #ff0;
	color: #000;
}

/**
     * Add the correct font size in all browsers.
     */
small {
	font-size: 80%;
}

/**
     * Prevent `sub` and `sup` elements from affecting the line height in
     * all browsers.
     */
sub,
sup {
	font-size: 75%;
	line-height: 0;
	position: relative;
	vertical-align: baseline;
}

sub {
	bottom: -0.25em;
}

sup {
	top: -0.5em;
}

/* Embedded content
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
audio,
video {
	display: inline-block;
}

/**
     * Add the correct display in iOS 4-7.
     */
audio:not([controls]) {
	display: none;
	height: 0;
}

/**
     * Remove the border on images inside links in IE 10-.
     */
img {
	border-style: none;
}

/**
     * Hide the overflow in IE.
     */
svg:not(:root) {
	overflow: hidden;
}

/* Forms
       ========================================================================== */
/**
     * 1. Change the font styles in all browsers (opinionated).
     * 2. Remove the margin in Firefox and Safari.
     */
button,
input,
optgroup,
select,
textarea {
	font-family: sans-serif;
	/* 1 */
	font-size: 100%;
	/* 1 */
	line-height: 1.15;
	/* 1 */
	margin: 0;
	/* 2 */
}

/**
     * Show the overflow in IE.
     */
button {
	overflow: visible;
}

/**
     * Remove the inheritance of text transform in Edge, Firefox, and IE.
     * 1. Remove the inheritance of text transform in Firefox.
     */
button,
select {
	/* 1 */
	text-transform: none;
}

/**
     * 1. Prevent a WebKit bug where (2) destroys native `audio` and `video`
     *    controls in Android 4.
     * 2. Correct the inability to style clickable types in iOS and Safari.
     */
button,
html [type='button'],
[type='reset'],
[type='submit'] {
	-webkit-appearance: button;
	/* 2 */
}

button,
[type='button'],
[type='reset'],
[type='submit'] {
	/**
       * Remove the inner border and padding in Firefox.
       */
	/**
       * Restore the focus styles unset by the previous rule.
       */
}
button::-moz-focus-inner,
[type='button']::-moz-focus-inner,
[type='reset']::-moz-focus-inner,
[type='submit']::-moz-focus-inner {
	border-style: none;
	padding: 0;
}
button:-moz-focusring,
[type='button']:-moz-focusring,
[type='reset']:-moz-focusring,
[type='submit']:-moz-focusring {
	outline: 1px dotted ButtonText;
}

/**
     * Show the overflow in Edge.
     */
input {
	overflow: visible;
}

/**
     * 1. Add the correct box sizing in IE 10-.
     * 2. Remove the padding in IE 10-.
     */
[type='checkbox'],
[type='radio'] {
	box-sizing: border-box;
	/* 1 */
	padding: 0;
	/* 2 */
}

/**
     * Correct the cursor style of increment and decrement buttons in Chrome.
     */
[type='number']::-webkit-inner-spin-button,
[type='number']::-webkit-outer-spin-button {
	height: auto;
}

/**
     * 1. Correct the odd appearance in Chrome and Safari.
     * 2. Correct the outline style in Safari.
     */
[type='search'] {
	-webkit-appearance: textfield;
	/* 1 */
	outline-offset: -2px;
	/* 2 */
	/**
       * Remove the inner padding and cancel buttons in Chrome and Safari on macOS.
       */
}
[type='search']::-webkit-search-cancel-button,
[type='search']::-webkit-search-decoration {
	-webkit-appearance: none;
}

/**
     * 1. Correct the inability to style clickable types in iOS and Safari.
     * 2. Change font properties to `inherit` in Safari.
     */
::-webkit-file-upload-button {
	-webkit-appearance: button;
	/* 1 */
	font: inherit;
	/* 2 */
}

/**
     * Correct the padding in Firefox.
     */
fieldset {
	padding: 0.35em 0.75em 0.625em;
}

/**
     * 1. Correct the text wrapping in Edge and IE.
     * 2. Correct the color inheritance from `fieldset` elements in IE.
     * 3. Remove the padding so developers are not caught out when they zero out
     *    `fieldset` elements in all browsers.
     */
legend {
	box-sizing: border-box;
	/* 1 */
	display: table;
	/* 1 */
	max-width: 100%;
	/* 1 */
	padding: 0;
	/* 3 */
	color: inherit;
	/* 2 */
	white-space: normal;
	/* 1 */
}

/**
     * 1. Add the correct display in IE 9-.
     * 2. Add the correct vertical alignment in Chrome, Firefox, and Opera.
     */
progress {
	display: inline-block;
	/* 1 */
	vertical-align: baseline;
	/* 2 */
}

/**
     * Remove the default vertical scrollbar in IE.
     */
textarea {
	overflow: auto;
}

/* Interactive
       ========================================================================== */
/*
     * Add the correct display in Edge, IE, and Firefox.
     */
details {
	display: block;
}

/*
     * Add the correct display in all browsers.
     */
summary {
	display: list-item;
}

/*
     * Add the correct display in IE 9-.
     */
menu {
	display: block;
}

/* Scripting
       ========================================================================== */
/**
     * Add the correct display in IE 9-.
     */
canvas {
	display: inline-block;
}

/**
     * Add the correct display in IE.
     */
template {
	display: none;
}

/* Hidden
       ========================================================================== */
/**
     * Add the correct display in IE 10-.
     */
[hidden] {
	display: none;
}

@-webkit-keyframes grid-appear-animation {
	0% {
		opacity: 0;
		height: 0;
		transform: initial;
	}
	100% {
		opacity: 1;
		transform: matrix3d(1, 0, 0, 0, 0, 1, 0, -0.012, 0, 0, 1, 0, 0, 0, 0, 1);
	}
}

@keyframes grid-appear-animation {
	0% {
		opacity: 0;
		height: 0;
		transform: initial;
	}
	100% {
		opacity: 1;
		transform: matrix3d(1, 0, 0, 0, 0, 1, 0, -0.012, 0, 0, 1, 0, 0, 0, 0, 1);
	}
}

@-webkit-keyframes grid-background-animation {
	from {
		background-position: 0 0;
	}
	to {
		background-position: 0 20px;
	}
}

@keyframes grid-background-animation {
	from {
		background-position: 0 0;
	}
	to {
		background-position: 0 20px;
	}
}

@-webkit-keyframes stars-animation {
	from {
		opacity: 0.3;
		transform: scale(0.3);
	}
	80% {
		opacity: 1;
	}
	to {
		opacity: 0;
		transform: scale(10);
	}
}

@keyframes stars-animation {
	from {
		opacity: 0.3;
		transform: scale(0.3);
	}
	80% {
		opacity: 1;
	}
	to {
		opacity: 0;
		transform: scale(10);
	}
}

@-webkit-keyframes logo-appear-animation {
	from {
		transform: scale(0) translateZ(0);
	}
	60% {
		transform: scale(1.5) translateZ(0);
	}
	to {
		transform: scale(1) translateZ(4vw);
	}
}

@keyframes logo-appear-animation {
	from {
		transform: scale(0) translateZ(0);
	}
	60% {
		transform: scale(1.5) translateZ(0);
	}
	to {
		transform: scale(1) translateZ(4vw);
	}
}

@-webkit-keyframes logo-background-animation {
	0% {
		transform: rotateY(0);
	}
	100% {
		transform: rotateY(720deg);
	}
}

@keyframes logo-background-animation {
	0% {
		transform: rotateY(0);
	}
	100% {
		transform: rotateY(720deg);
	}
}

@-webkit-keyframes logo-text-animation {
	0% {
		opacity: 0;
	}
	90% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

@keyframes logo-text-animation {
	0% {
		opacity: 0;
	}
	90% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

@-webkit-keyframes noise-white-background-animation {
	0% {
		display: block;
		background: linear-gradient(90deg, transparent, #fff, transparent, transparent, transparent, transparent, #fff, transparent) 50% 56% no-repeat,
			linear-gradient(90deg, transparent, transparent, transparent, transparent, transparent, transparent, #fff, transparent) 21% 36% no-repeat,
			linear-gradient(90deg, transparent, #fff, transparent, #fff, #fff, transparent, transparent, transparent) 6% 22% no-repeat,
			linear-gradient(90deg, transparent, #fff, #fff, #fff, #fff, #fff, transparent, transparent) 3% 64% no-repeat,
			linear-gradient(90deg, transparent, transparent, #fff, transparent, #fff, #fff, transparent, transparent) 53% 70% no-repeat,
			linear-gradient(90deg, transparent, #fff, #fff, transparent, #fff, transparent, #fff, transparent) 86% 61% no-repeat;
		background-size: 40% 1px, 50% 1px, 42% 1px, 47% 1px, 40% 1px, 45% 1px;
		-webkit-animation-timing-function: steps(1);
		animation-timing-function: steps(1);
	}
	2% {
		display: none;
		background: 0;
		background-size: 0;
		-webkit-animation-timing-function: steps(2);
		animation-timing-function: steps(2);
	}
	19% {
		display: none;
	}
	20% {
		display: block;
		background: linear-gradient(90deg, transparent, #fff, #fff, transparent, transparent, #fff, transparent, transparent) 58% 55% no-repeat,
			linear-gradient(90deg, transparent, transparent, #fff, transparent, transparent, transparent, transparent, transparent) 49% 98% no-repeat;
		background-size: 1% 1px, 48% 1px;
		-webkit-animation-timing-function: steps(4);
		animation-timing-function: steps(4);
	}
	22% {
		display: none;
		background: 0;
		background-size: 0;
		-webkit-animation-timing-function: steps(5);
		animation-timing-function: steps(5);
	}
	100% {
		display: none;
	}
}

@keyframes noise-white-background-animation {
	0% {
		display: block;
		background: linear-gradient(90deg, transparent, #fff, transparent, transparent, transparent, transparent, #fff, transparent) 50% 56% no-repeat,
			linear-gradient(90deg, transparent, transparent, transparent, transparent, transparent, transparent, #fff, transparent) 21% 36% no-repeat,
			linear-gradient(90deg, transparent, #fff, transparent, #fff, #fff, transparent, transparent, transparent) 6% 22% no-repeat,
			linear-gradient(90deg, transparent, #fff, #fff, #fff, #fff, #fff, transparent, transparent) 3% 64% no-repeat,
			linear-gradient(90deg, transparent, transparent, #fff, transparent, #fff, #fff, transparent, transparent) 53% 70% no-repeat,
			linear-gradient(90deg, transparent, #fff, #fff, transparent, #fff, transparent, #fff, transparent) 86% 61% no-repeat;
		background-size: 40% 1px, 50% 1px, 42% 1px, 47% 1px, 40% 1px, 45% 1px;
		-webkit-animation-timing-function: steps(1);
		animation-timing-function: steps(1);
	}
	2% {
		display: none;
		background: 0;
		background-size: 0;
		-webkit-animation-timing-function: steps(2);
		animation-timing-function: steps(2);
	}
	19% {
		display: none;
	}
	20% {
		display: block;
		background: linear-gradient(90deg, transparent, #fff, #fff, transparent, transparent, #fff, transparent, transparent) 58% 55% no-repeat,
			linear-gradient(90deg, transparent, transparent, #fff, transparent, transparent, transparent, transparent, transparent) 49% 98% no-repeat;
		background-size: 1% 1px, 48% 1px;
		-webkit-animation-timing-function: steps(4);
		animation-timing-function: steps(4);
	}
	22% {
		display: none;
		background: 0;
		background-size: 0;
		-webkit-animation-timing-function: steps(5);
		animation-timing-function: steps(5);
	}
	100% {
		display: none;
	}
}

@-webkit-keyframes noise-black-animation {
	0% {
		top: 0;
	}
	25% {
		top: 100%;
		display: none;
	}
	100% {
		top: 100%;
		display: none;
	}
}

@keyframes noise-black-animation {
	0% {
		top: 0;
	}
	25% {
		top: 100%;
		display: none;
	}
	100% {
		top: 100%;
		display: none;
	}
}

@-webkit-keyframes text-neon-glitch {
	0% {
		opacity: 1;
	}
	20% {
		opacity: 1;
	}
	20.001% {
		opacity: 0;
	}
	25% {
		opacity: 0;
	}
	25.001% {
		opacity: 1;
	}
	45% {
		opacity: 1;
	}
	45.001% {
		opacity: 0;
	}
	46% {
		opacity: 0;
	}
	46.001% {
		opacity: 1;
	}
	48% {
		opacity: 0;
	}
	48.001% {
		opacity: 1;
	}
	100% {
		opacity: 1;
	}
}

@keyframes text-neon-glitch {
	0% {
		opacity: 1;
	}
	20% {
		opacity: 1;
	}
	20.001% {
		opacity: 0;
	}
	25% {
		opacity: 0;
	}
	25.001% {
		opacity: 1;
	}
	45% {
		opacity: 1;
	}
	45.001% {
		opacity: 0;
	}
	46% {
		opacity: 0;
	}
	46.001% {
		opacity: 1;
	}
	48% {
		opacity: 0;
	}
	48.001% {
		opacity: 1;
	}
	100% {
		opacity: 1;
	}
}

@-webkit-keyframes cursor-blinking {
	from {
		opacity: 1;
	}
	to {
		opacity: 0;
	}
}

@keyframes cursor-blinking {
	from {
		opacity: 1;
	}
	to {
		opacity: 0;
	}
}

@-webkit-keyframes sun-colors {
	0%,
	100% {
		background-position: 0 0;
	}
	50% {
		background-position: 0 100%;
	}
}

@keyframes sun-colors {
	0%,
	100% {
		background-position: 0 0;
	}
	50% {
		background-position: 0 100%;
	}
}

@-webkit-keyframes sun-move-up {
	from {
		top: 40px;
	}
	to {
		top: 0;
	}
}

@keyframes sun-move-up {
	from {
		top: 40px;
	}
	to {
		top: 0;
	}
}

@-webkit-keyframes sun-move-down {
	from {
		top: 0;
	}
	to {
		top: 40px;
	}
}

@keyframes sun-move-down {
	from {
		top: 0;
	}
	to {
		top: 40px;
	}
}

@-webkit-keyframes palm-little-move {
	0% {
		-webkit-clip-path: polygon(
			51.68% 100%,
			53.67% 78.98%,
			50.68% 60.34%,
			44.64% 47.96%,
			32.31% 51.65%,
			24.29% 67.58%,
			24.97% 54.53%,
			28.28% 45.52%,
			34.72% 41.22%,
			26.77% 43.41%,
			19.15% 48.81%,
			12.69% 58.22%,
			14.27% 42.12%,
			22.07% 34%,
			28.95% 29.08%,
			21.96% 30.94%,
			21.46% 23.14%,
			19.68% 31.5%,
			12.61% 35.05%,
			2.55% 42.82%,
			9.92% 24.02%,
			19.4% 17.02%,
			32.01% 15.83%,
			39.41% 18.88%,
			35.82% 11.64%,
			21.77% 8.11%,
			34.82% 6.71%,
			43.57% 9.01%,
			50.71% 17.57%,
			59.18% 15.22%,
			69.29% 15.86%,
			77.46% 24.81%,
			65.64% 21.63%,
			57.54% 24.89%,
			68.82% 26.67%,
			77.55% 34.43%,
			83.18% 50.75%,
			71.6% 40.67%,
			61.52% 38.71%,
			69.14% 45.18%,
			72.25% 58.42%,
			62.61% 48.85%,
			60.27% 43.79%,
			59.7% 48.06%,
			54.16% 46.69%,
			61.1% 62.31%,
			64.68% 79.41%,
			64.28% 100%
		);
		clip-path: polygon(
			51.68% 100%,
			53.67% 78.98%,
			50.68% 60.34%,
			44.64% 47.96%,
			32.31% 51.65%,
			24.29% 67.58%,
			24.97% 54.53%,
			28.28% 45.52%,
			34.72% 41.22%,
			26.77% 43.41%,
			19.15% 48.81%,
			12.69% 58.22%,
			14.27% 42.12%,
			22.07% 34%,
			28.95% 29.08%,
			21.96% 30.94%,
			21.46% 23.14%,
			19.68% 31.5%,
			12.61% 35.05%,
			2.55% 42.82%,
			9.92% 24.02%,
			19.4% 17.02%,
			32.01% 15.83%,
			39.41% 18.88%,
			35.82% 11.64%,
			21.77% 8.11%,
			34.82% 6.71%,
			43.57% 9.01%,
			50.71% 17.57%,
			59.18% 15.22%,
			69.29% 15.86%,
			77.46% 24.81%,
			65.64% 21.63%,
			57.54% 24.89%,
			68.82% 26.67%,
			77.55% 34.43%,
			83.18% 50.75%,
			71.6% 40.67%,
			61.52% 38.71%,
			69.14% 45.18%,
			72.25% 58.42%,
			62.61% 48.85%,
			60.27% 43.79%,
			59.7% 48.06%,
			54.16% 46.69%,
			61.1% 62.31%,
			64.68% 79.41%,
			64.28% 100%
		);
	}
	100% {
		transform: skewX(4deg);
		-webkit-clip-path: polygon(
			51.68% 100%,
			53.67% 78.98%,
			50.68% 60.34%,
			44.64% 47.96%,
			32.98% 51.65%,
			24.95% 68.92%,
			25.64% 55.2%,
			28.28% 45.85%,
			34.72% 41.22%,
			26.44% 44.08%,
			19.48% 49.48%,
			12.69% 59.89%,
			14.27% 43.12%,
			22.4% 35%,
			28.62% 29.75%,
			21.63% 32.27%,
			21.13% 24.14%,
			19.35% 32.83%,
			12.61% 36.05%,
			2.89% 44.82%,
			9.58% 25.35%,
			19.4% 18.02%,
			31.01% 16.5%,
			39.41% 18.88%,
			33.49% 11.64%,
			20.44% 9.44%,
			32.49% 6.71%,
			41.24% 9.34%,
			50.71% 17.57%,
			59.18% 15.22%,
			70.29% 15.86%,
			78.79% 24.48%,
			66.31% 22.3%,
			57.54% 24.89%,
			69.49% 26.67%,
			78.22% 34.1%,
			84.18% 50.42%,
			71.93% 40%,
			61.52% 38.71%,
			69.81% 45.85%,
			73.25% 57.75%,
			62.94% 48.85%,
			60.27% 44.12%,
			60.03% 47.73%,
			54.16% 46.69%,
			61.1% 62.31%,
			64.68% 79.41%,
			64.28% 100%
		);
		clip-path: polygon(
			51.68% 100%,
			53.67% 78.98%,
			50.68% 60.34%,
			44.64% 47.96%,
			32.98% 51.65%,
			24.95% 68.92%,
			25.64% 55.2%,
			28.28% 45.85%,
			34.72% 41.22%,
			26.44% 44.08%,
			19.48% 49.48%,
			12.69% 59.89%,
			14.27% 43.12%,
			22.4% 35%,
			28.62% 29.75%,
			21.63% 32.27%,
			21.13% 24.14%,
			19.35% 32.83%,
			12.61% 36.05%,
			2.89% 44.82%,
			9.58% 25.35%,
			19.4% 18.02%,
			31.01% 16.5%,
			39.41% 18.88%,
			33.49% 11.64%,
			20.44% 9.44%,
			32.49% 6.71%,
			41.24% 9.34%,
			50.71% 17.57%,
			59.18% 15.22%,
			70.29% 15.86%,
			78.79% 24.48%,
			66.31% 22.3%,
			57.54% 24.89%,
			69.49% 26.67%,
			78.22% 34.1%,
			84.18% 50.42%,
			71.93% 40%,
			61.52% 38.71%,
			69.81% 45.85%,
			73.25% 57.75%,
			62.94% 48.85%,
			60.27% 44.12%,
			60.03% 47.73%,
			54.16% 46.69%,
			61.1% 62.31%,
			64.68% 79.41%,
			64.28% 100%
		);
	}
}

@keyframes palm-little-move {
	0% {
		-webkit-clip-path: polygon(
			51.68% 100%,
			53.67% 78.98%,
			50.68% 60.34%,
			44.64% 47.96%,
			32.31% 51.65%,
			24.29% 67.58%,
			24.97% 54.53%,
			28.28% 45.52%,
			34.72% 41.22%,
			26.77% 43.41%,
			19.15% 48.81%,
			12.69% 58.22%,
			14.27% 42.12%,
			22.07% 34%,
			28.95% 29.08%,
			21.96% 30.94%,
			21.46% 23.14%,
			19.68% 31.5%,
			12.61% 35.05%,
			2.55% 42.82%,
			9.92% 24.02%,
			19.4% 17.02%,
			32.01% 15.83%,
			39.41% 18.88%,
			35.82% 11.64%,
			21.77% 8.11%,
			34.82% 6.71%,
			43.57% 9.01%,
			50.71% 17.57%,
			59.18% 15.22%,
			69.29% 15.86%,
			77.46% 24.81%,
			65.64% 21.63%,
			57.54% 24.89%,
			68.82% 26.67%,
			77.55% 34.43%,
			83.18% 50.75%,
			71.6% 40.67%,
			61.52% 38.71%,
			69.14% 45.18%,
			72.25% 58.42%,
			62.61% 48.85%,
			60.27% 43.79%,
			59.7% 48.06%,
			54.16% 46.69%,
			61.1% 62.31%,
			64.68% 79.41%,
			64.28% 100%
		);
		clip-path: polygon(
			51.68% 100%,
			53.67% 78.98%,
			50.68% 60.34%,
			44.64% 47.96%,
			32.31% 51.65%,
			24.29% 67.58%,
			24.97% 54.53%,
			28.28% 45.52%,
			34.72% 41.22%,
			26.77% 43.41%,
			19.15% 48.81%,
			12.69% 58.22%,
			14.27% 42.12%,
			22.07% 34%,
			28.95% 29.08%,
			21.96% 30.94%,
			21.46% 23.14%,
			19.68% 31.5%,
			12.61% 35.05%,
			2.55% 42.82%,
			9.92% 24.02%,
			19.4% 17.02%,
			32.01% 15.83%,
			39.41% 18.88%,
			35.82% 11.64%,
			21.77% 8.11%,
			34.82% 6.71%,
			43.57% 9.01%,
			50.71% 17.57%,
			59.18% 15.22%,
			69.29% 15.86%,
			77.46% 24.81%,
			65.64% 21.63%,
			57.54% 24.89%,
			68.82% 26.67%,
			77.55% 34.43%,
			83.18% 50.75%,
			71.6% 40.67%,
			61.52% 38.71%,
			69.14% 45.18%,
			72.25% 58.42%,
			62.61% 48.85%,
			60.27% 43.79%,
			59.7% 48.06%,
			54.16% 46.69%,
			61.1% 62.31%,
			64.68% 79.41%,
			64.28% 100%
		);
	}
	100% {
		transform: skewX(4deg);
		-webkit-clip-path: polygon(
			51.68% 100%,
			53.67% 78.98%,
			50.68% 60.34%,
			44.64% 47.96%,
			32.98% 51.65%,
			24.95% 68.92%,
			25.64% 55.2%,
			28.28% 45.85%,
			34.72% 41.22%,
			26.44% 44.08%,
			19.48% 49.48%,
			12.69% 59.89%,
			14.27% 43.12%,
			22.4% 35%,
			28.62% 29.75%,
			21.63% 32.27%,
			21.13% 24.14%,
			19.35% 32.83%,
			12.61% 36.05%,
			2.89% 44.82%,
			9.58% 25.35%,
			19.4% 18.02%,
			31.01% 16.5%,
			39.41% 18.88%,
			33.49% 11.64%,
			20.44% 9.44%,
			32.49% 6.71%,
			41.24% 9.34%,
			50.71% 17.57%,
			59.18% 15.22%,
			70.29% 15.86%,
			78.79% 24.48%,
			66.31% 22.3%,
			57.54% 24.89%,
			69.49% 26.67%,
			78.22% 34.1%,
			84.18% 50.42%,
			71.93% 40%,
			61.52% 38.71%,
			69.81% 45.85%,
			73.25% 57.75%,
			62.94% 48.85%,
			60.27% 44.12%,
			60.03% 47.73%,
			54.16% 46.69%,
			61.1% 62.31%,
			64.68% 79.41%,
			64.28% 100%
		);
		clip-path: polygon(
			51.68% 100%,
			53.67% 78.98%,
			50.68% 60.34%,
			44.64% 47.96%,
			32.98% 51.65%,
			24.95% 68.92%,
			25.64% 55.2%,
			28.28% 45.85%,
			34.72% 41.22%,
			26.44% 44.08%,
			19.48% 49.48%,
			12.69% 59.89%,
			14.27% 43.12%,
			22.4% 35%,
			28.62% 29.75%,
			21.63% 32.27%,
			21.13% 24.14%,
			19.35% 32.83%,
			12.61% 36.05%,
			2.89% 44.82%,
			9.58% 25.35%,
			19.4% 18.02%,
			31.01% 16.5%,
			39.41% 18.88%,
			33.49% 11.64%,
			20.44% 9.44%,
			32.49% 6.71%,
			41.24% 9.34%,
			50.71% 17.57%,
			59.18% 15.22%,
			70.29% 15.86%,
			78.79% 24.48%,
			66.31% 22.3%,
			57.54% 24.89%,
			69.49% 26.67%,
			78.22% 34.1%,
			84.18% 50.42%,
			71.93% 40%,
			61.52% 38.71%,
			69.81% 45.85%,
			73.25% 57.75%,
			62.94% 48.85%,
			60.27% 44.12%,
			60.03% 47.73%,
			54.16% 46.69%,
			61.1% 62.31%,
			64.68% 79.41%,
			64.28% 100%
		);
	}
}

@-webkit-keyframes palm-big-move {
	0% {
		-webkit-clip-path: polygon(
			32% 100%,
			28.59% 87.48%,
			27% 73%,
			30.34% 58.75%,
			37.33% 44.5%,
			27% 47%,
			20.67% 50.75%,
			13.33% 59.5%,
			15.33% 48.25%,
			21% 42%,
			28% 35%,
			20.92% 35.97%,
			22.33% 30.5%,
			16.75% 37.05%,
			3% 45%,
			14% 30%,
			27% 24.75%,
			37% 25.25%,
			24.67% 21%,
			7.67% 22.25%,
			21.94% 17.46%,
			32.67% 17%,
			42.67% 19.5%,
			49% 14.25%,
			59.04% 11.57%,
			74% 12.5%,
			57.68% 15.75%,
			54.34% 20.5%,
			66.67% 18.5%,
			81.66% 20.75%,
			93% 30%,
			99% 48%,
			82.6% 35.48%,
			81.34% 27%,
			79.31% 34.75%,
			63.33% 30.25%,
			79% 39%,
			84% 52%,
			72% 45%,
			60% 41%,
			66% 50%,
			68% 62.5%,
			62% 53%,
			47.34% 44.75%,
			40% 60.25%,
			38.33% 76.25%,
			41.4% 89.25%,
			47.33% 100%
		);
		clip-path: polygon(
			32% 100%,
			28.59% 87.48%,
			27% 73%,
			30.34% 58.75%,
			37.33% 44.5%,
			27% 47%,
			20.67% 50.75%,
			13.33% 59.5%,
			15.33% 48.25%,
			21% 42%,
			28% 35%,
			20.92% 35.97%,
			22.33% 30.5%,
			16.75% 37.05%,
			3% 45%,
			14% 30%,
			27% 24.75%,
			37% 25.25%,
			24.67% 21%,
			7.67% 22.25%,
			21.94% 17.46%,
			32.67% 17%,
			42.67% 19.5%,
			49% 14.25%,
			59.04% 11.57%,
			74% 12.5%,
			57.68% 15.75%,
			54.34% 20.5%,
			66.67% 18.5%,
			81.66% 20.75%,
			93% 30%,
			99% 48%,
			82.6% 35.48%,
			81.34% 27%,
			79.31% 34.75%,
			63.33% 30.25%,
			79% 39%,
			84% 52%,
			72% 45%,
			60% 41%,
			66% 50%,
			68% 62.5%,
			62% 53%,
			47.34% 44.75%,
			40% 60.25%,
			38.33% 76.25%,
			41.4% 89.25%,
			47.33% 100%
		);
	}
	100% {
		transform: skewX(4deg);
		-webkit-clip-path: polygon(
			32% 100%,
			28.59% 87.48%,
			27% 73%,
			30.34% 58.75%,
			37.33% 44.5%,
			27% 46.5%,
			20% 50.25%,
			10.33% 58.75%,
			14.66% 48.25%,
			20.33% 41.5%,
			28% 35%,
			21.92% 35.97%,
			23% 30%,
			18.08% 37.05%,
			4.67% 44.5%,
			15.33% 29.75%,
			27% 24.75%,
			37% 25.25%,
			24.67% 21%,
			8% 23%,
			21.94% 17.96%,
			32.67% 17%,
			42.67% 19.5%,
			49% 13.75%,
			58.37% 11.32%,
			73% 11%,
			57.35% 15.25%,
			54.34% 20.5%,
			66.67% 18.25%,
			82.33% 19.25%,
			93.67% 28.5%,
			100.67% 46%,
			83.6% 34.23%,
			81.34% 26%,
			79.31% 33.75%,
			63.33% 30.25%,
			78.67% 37.75%,
			85% 51%,
			72.33% 43.5%,
			60% 41%,
			65.33% 50%,
			65.33% 63%,
			60.33% 53%,
			47.34% 44.75%,
			40% 60.25%,
			38.33% 76.25%,
			41.4% 89.25%,
			47.33% 100%
		);
		clip-path: polygon(
			32% 100%,
			28.59% 87.48%,
			27% 73%,
			30.34% 58.75%,
			37.33% 44.5%,
			27% 46.5%,
			20% 50.25%,
			10.33% 58.75%,
			14.66% 48.25%,
			20.33% 41.5%,
			28% 35%,
			21.92% 35.97%,
			23% 30%,
			18.08% 37.05%,
			4.67% 44.5%,
			15.33% 29.75%,
			27% 24.75%,
			37% 25.25%,
			24.67% 21%,
			8% 23%,
			21.94% 17.96%,
			32.67% 17%,
			42.67% 19.5%,
			49% 13.75%,
			58.37% 11.32%,
			73% 11%,
			57.35% 15.25%,
			54.34% 20.5%,
			66.67% 18.25%,
			82.33% 19.25%,
			93.67% 28.5%,
			100.67% 46%,
			83.6% 34.23%,
			81.34% 26%,
			79.31% 33.75%,
			63.33% 30.25%,
			78.67% 37.75%,
			85% 51%,
			72.33% 43.5%,
			60% 41%,
			65.33% 50%,
			65.33% 63%,
			60.33% 53%,
			47.34% 44.75%,
			40% 60.25%,
			38.33% 76.25%,
			41.4% 89.25%,
			47.33% 100%
		);
	}
}

@keyframes palm-big-move {
	0% {
		-webkit-clip-path: polygon(
			32% 100%,
			28.59% 87.48%,
			27% 73%,
			30.34% 58.75%,
			37.33% 44.5%,
			27% 47%,
			20.67% 50.75%,
			13.33% 59.5%,
			15.33% 48.25%,
			21% 42%,
			28% 35%,
			20.92% 35.97%,
			22.33% 30.5%,
			16.75% 37.05%,
			3% 45%,
			14% 30%,
			27% 24.75%,
			37% 25.25%,
			24.67% 21%,
			7.67% 22.25%,
			21.94% 17.46%,
			32.67% 17%,
			42.67% 19.5%,
			49% 14.25%,
			59.04% 11.57%,
			74% 12.5%,
			57.68% 15.75%,
			54.34% 20.5%,
			66.67% 18.5%,
			81.66% 20.75%,
			93% 30%,
			99% 48%,
			82.6% 35.48%,
			81.34% 27%,
			79.31% 34.75%,
			63.33% 30.25%,
			79% 39%,
			84% 52%,
			72% 45%,
			60% 41%,
			66% 50%,
			68% 62.5%,
			62% 53%,
			47.34% 44.75%,
			40% 60.25%,
			38.33% 76.25%,
			41.4% 89.25%,
			47.33% 100%
		);
		clip-path: polygon(
			32% 100%,
			28.59% 87.48%,
			27% 73%,
			30.34% 58.75%,
			37.33% 44.5%,
			27% 47%,
			20.67% 50.75%,
			13.33% 59.5%,
			15.33% 48.25%,
			21% 42%,
			28% 35%,
			20.92% 35.97%,
			22.33% 30.5%,
			16.75% 37.05%,
			3% 45%,
			14% 30%,
			27% 24.75%,
			37% 25.25%,
			24.67% 21%,
			7.67% 22.25%,
			21.94% 17.46%,
			32.67% 17%,
			42.67% 19.5%,
			49% 14.25%,
			59.04% 11.57%,
			74% 12.5%,
			57.68% 15.75%,
			54.34% 20.5%,
			66.67% 18.5%,
			81.66% 20.75%,
			93% 30%,
			99% 48%,
			82.6% 35.48%,
			81.34% 27%,
			79.31% 34.75%,
			63.33% 30.25%,
			79% 39%,
			84% 52%,
			72% 45%,
			60% 41%,
			66% 50%,
			68% 62.5%,
			62% 53%,
			47.34% 44.75%,
			40% 60.25%,
			38.33% 76.25%,
			41.4% 89.25%,
			47.33% 100%
		);
	}
	100% {
		transform: skewX(4deg);
		-webkit-clip-path: polygon(
			32% 100%,
			28.59% 87.48%,
			27% 73%,
			30.34% 58.75%,
			37.33% 44.5%,
			27% 46.5%,
			20% 50.25%,
			10.33% 58.75%,
			14.66% 48.25%,
			20.33% 41.5%,
			28% 35%,
			21.92% 35.97%,
			23% 30%,
			18.08% 37.05%,
			4.67% 44.5%,
			15.33% 29.75%,
			27% 24.75%,
			37% 25.25%,
			24.67% 21%,
			8% 23%,
			21.94% 17.96%,
			32.67% 17%,
			42.67% 19.5%,
			49% 13.75%,
			58.37% 11.32%,
			73% 11%,
			57.35% 15.25%,
			54.34% 20.5%,
			66.67% 18.25%,
			82.33% 19.25%,
			93.67% 28.5%,
			100.67% 46%,
			83.6% 34.23%,
			81.34% 26%,
			79.31% 33.75%,
			63.33% 30.25%,
			78.67% 37.75%,
			85% 51%,
			72.33% 43.5%,
			60% 41%,
			65.33% 50%,
			65.33% 63%,
			60.33% 53%,
			47.34% 44.75%,
			40% 60.25%,
			38.33% 76.25%,
			41.4% 89.25%,
			47.33% 100%
		);
		clip-path: polygon(
			32% 100%,
			28.59% 87.48%,
			27% 73%,
			30.34% 58.75%,
			37.33% 44.5%,
			27% 46.5%,
			20% 50.25%,
			10.33% 58.75%,
			14.66% 48.25%,
			20.33% 41.5%,
			28% 35%,
			21.92% 35.97%,
			23% 30%,
			18.08% 37.05%,
			4.67% 44.5%,
			15.33% 29.75%,
			27% 24.75%,
			37% 25.25%,
			24.67% 21%,
			8% 23%,
			21.94% 17.96%,
			32.67% 17%,
			42.67% 19.5%,
			49% 13.75%,
			58.37% 11.32%,
			73% 11%,
			57.35% 15.25%,
			54.34% 20.5%,
			66.67% 18.25%,
			82.33% 19.25%,
			93.67% 28.5%,
			100.67% 46%,
			83.6% 34.23%,
			81.34% 26%,
			79.31% 33.75%,
			63.33% 30.25%,
			78.67% 37.75%,
			85% 51%,
			72.33% 43.5%,
			60% 41%,
			65.33% 50%,
			65.33% 63%,
			60.33% 53%,
			47.34% 44.75%,
			40% 60.25%,
			38.33% 76.25%,
			41.4% 89.25%,
			47.33% 100%
		);
	}
}

@-webkit-keyframes text-glitch {
	0% {
		text-shadow: 10px 6px 0 #f000be;
	}
	33% {
		text-shadow: -4px -3px 0 yellow;
	}
	66% {
		text-shadow: 5px 3px 0 #82ffff;
	}
}

@keyframes text-glitch {
	0% {
		text-shadow: 10px 6px 0 #f000be;
	}
	33% {
		text-shadow: -4px -3px 0 yellow;
	}
	66% {
		text-shadow: 5px 3px 0 #82ffff;
	}
}

.log {
	position: fixed;
	font-family: monospace;
	z-index: 100;
	display: none;
}

body {
	--mouseX: 0;
	--mouseY: 0;
	--random1: 0;
	--random2: 0;
	--font-fallback: -apple-system, system-ui, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
	position: relative;
	background: #141414;
	color: white;
	font-family: var(--font-fallback);
	transition: all 0.2s linear;
}

.hidden {
	display: none !important;
}

.screen-fixed {
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
}

.screen-pixels {
	background: repeating-linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2) 2px, transparent 2px, transparent 4px);
	z-index: 100;
	pointer-events: none;
}

.noise-white {
	opacity: 0.3;
	z-index: 50;
	-webkit-animation: noise-white-background-animation 4s linear infinite;
	animation: noise-white-background-animation 4s linear infinite;
	pointer-events: none;
}

.noise-black {
	z-index: 50;
	pointer-events: none;
}
.noise-black::before {
	display: block;
	content: '';
	position: absolute;
	top: 0;
	width: 100%;
	height: 2px;
	background: #141414;
	-webkit-animation: noise-black-animation 10s linear infinite;
	animation: noise-black-animation 10s linear infinite;
	opacity: calc(var(--random2));
}

.audio-player audio {
	display: none;
}

.audio-player .controls {
	position: absolute;
	right: 15px;
	bottom: 15px;
}
.audio-player .controls .control {
	font-family: monospace;
	font-size: 2.5em;
	line-height: 1;
	cursor: pointer;
}
.audio-player .controls .control::before {
	display: block;
}
.audio-player .controls .control:hover::before {
	-webkit-animation: text-glitch calc(var(--random1) * 1s) calc(var(--random2) * 2) steps(3, start);
	animation: text-glitch calc(var(--random1) * 1s) calc(var(--random2) * 2) steps(3, start);
}
.audio-player .controls .play::before {
	content: '▷';
}
.audio-player .controls .pause {
	font-size: 3em;
}
.audio-player .controls .pause::before {
	content: '=';
	position: relative;
	top: 8px;
	transform: rotate(90deg);
}

.global-container {
	overflow: hidden;
}

.scene {
	position: relative;
	width: 100%;
	min-height: 100vh;
}

.animatable::after {
	content: '';
	display: inline-block;
	width: 1em;
	height: 1em;
	background: #82ffff;
	opacity: 0;
}

.animatable.animating::after {
	opacity: 1;
}

.animatable.animated::after {
	-webkit-animation: cursor-blinking 1s linear infinite;
	animation: cursor-blinking 1s linear infinite;
}

.animatable.waiting {
	visibility: hidden;
}

.flex {
	position: relative;
	display: flex;
}
.flex-center {
	justify-content: center;
	align-content: center;
	align-items: center;
}

.intro {
	transition: all 0.2s linear;
}
.intro::before {
	content: '';
	display: block;
	width: 200vw;
	height: 50vh;
	position: absolute;
	top: 25%;
	left: -50%;
	z-index: -1;
	background-image: repeating-linear-gradient(to top, #1e0aaa, #1e0aaa 1px, transparent 1px, transparent 10px),
		repeating-linear-gradient(90deg, #1e0aaa, #1e0aaa 3px, transparent 3px, transparent 100px);
	-webkit-animation: grid-appear-animation 4s cubic-bezier(0, 0.5, 0.5, 1), grid-background-animation 1s linear infinite;
	animation: grid-appear-animation 4s cubic-bezier(0, 0.5, 0.5, 1), grid-background-animation 1s linear infinite;
	transform: matrix3d(1, 0, 0, 0, 0, 1, 0, -0.012, 0, 0, 1, 0, 0, 0, 0, 1);
	-webkit-mask-image: linear-gradient(to bottom, transparent 30%, black 60%);
}

.stars::before,
.stars::after {
	content: '';
	display: block;
	width: 1px;
	height: 1px;
	position: absolute;
	top: 50%;
	left: 50%;
	opacity: 0;
	box-shadow: 15vw -1vh white, -39vw -33vh white, 17vw -48vh white, -13vw -48vh white, -47vw -1vh white, -46vw -7vh white, 32vw -2vh white,
		30vw 12vh white, -23vw 43vh white, 35vw -32vh white, -36vw -16vh white, 14vw 14vh white, -24vw 24vh white, 7vw 47vh white, -38vw -12vh white,
		25vw 14vh white, -39vw -33vh white, 5vw -45vh white, -25vw -7vh white, 47vw 11vh white, -15vw -4vh white, 21vw -45vh white, -20vw -49vh white,
		-23vw 9vh white, 2vw -7vh white, 14vw -9vh white, 45vw -15vh white, -40vw -8vh white, 23vw -30vh white, -6vw -46vh white, -20vw 19vh white,
		-28vw -28vh white, 35vw 34vh white, 40vw -27vh white, 44vw -45vh white, 5vw -43vh white, 31vw 39vh white, -34vw 17vh white, -10vw -44vh white,
		-30vw -7vh white, -12vw -15vh white, -37vw -26vh white, 25vw -31vh white, -44vw -9vh white, 10vw -46vh white, 26vw -9vh white, 8vw 1vh white,
		-14vw 14vh white, -32vw -36vh white, 38vw 27vh white, -43vw 3vh white, -41vw -33vh white, -23vw 23vh white, -7vw 50vh white, -39vw -35vh white,
		23vw 46vh white, -24vw 16vh white, 28vw 13vh white, 28vw -22vh white, 46vw -21vh white, 6vw 37vh white, 30vw -27vh white, 29vw 18vh white,
		-17vw 14vh white, -29vw -23vh white, -43vw 20vh white, -19vw -5vh white, -9vw 41vh white, -3vw -44vh white, 42vw -34vh white, 25vw -23vh white,
		-34vw 26vh white, 25vw 3vh white, -5vw 24vh white, -27vw -43vh white, 4vw -37vh white, -32vw -3vh white, 24vw 16vh white, 30vw -20vh white,
		-35vw -23vh white, 19vw -23vh white;
	-webkit-animation: stars-animation 4s cubic-bezier(0.8, 0, 1, 0.8) infinite;
	animation: stars-animation 4s cubic-bezier(0.8, 0, 1, 0.8) infinite;
}

.stars::after {
	box-shadow: -43vw -37vh white, -20vw 33vh white, -24vw 34vh white, -7vw 35vh white, 41vw -28vh white, 3vw 22vh white, -37vw -7vh white,
		6vw -1vh white, 17vw 25vh white, 42vw -46vh white, -6vw 18vh white, -30vw 16vh white, -3vw -12vh white, -5vw -19vh white, -15vw -36vh white,
		-41vw 1vh white, 38vw -11vh white, 43vw -6vh white, -9vw -43vh white, 37vw -40vh white, -20vw -40vh white, -15vw -8vh white, 10vw -36vh white,
		46vw 35vh white, 21vw -5vh white, -42vw 7vh white, 49vw 34vh white, -26vw -16vh white, -9vw -49vh white, -7vw 18vh white, 22vw -8vh white,
		-35vw 32vh white, -19vw 49vh white, -38vw 20vh white, 49vw 4vh white, -25vw -13vh white, -12vw -8vh white, 11vw 3vh white, 21vw 41vh white,
		35vw 5vh white, 46vw -40vh white, -25vw 12vh white, 3vw 4vh white, 30vw -42vh white, 12vw -31vh white, 4vw -6vh white, 19vw -44vh white,
		35vw -28vh white, 26vw -31vh white, -3vw 47vh white, 49vw 37vh white, 9vw -5vh white, -40vw -45vh white, 34vw -4vh white, -6vw 14vh white,
		36vw -32vh white, -46vw -8vh white, -43vw 47vh white, 27vw -20vh white, 35vw 32vh white, 33vw 10vh white, -16vw -31vh white, -41vw -44vh white,
		11vw -48vh white, -27vw 48vh white, -34vw 14vh white, -46vw 3vh white, -46vw -25vh white, -8vw -7vh white, 50vw -3vh white, 30vw -42vh white,
		15vw -2vh white, -13vw -40vh white, -28vw 1vh white, 44vw 5vh white, -39vw 43vh white, -29vw -38vh white, -26vw 35vh white, -8vw -10vh white,
		-12vw -16vh white, -30vw -14vh white;
	-webkit-animation-delay: 1s;
	animation-delay: 1s;
}

.stars-2::before {
	box-shadow: -4vw 27vh white, -46vw -37vh white, -15vw 41vh white, 29vw 18vh white, -5vw 26vh white, 31vw -32vh white, 35vw -23vh white,
		44vw 43vh white, -47vw -32vh white, -23vw 16vh white, 3vw -30vh white, 36vw 23vh white, -42vw 16vh white, 39vw -36vh white, 38vw 30vh white,
		26vw -32vh white, -29vw -39vh white, -25vw -38vh white, 46vw 13vh white, 28vw -22vh white, 28vw -21vh white, -27vw 15vh white,
		-26vw -28vh white, 21vw 47vh white, 43vw 48vh white, 6vw -19vh white, 21vw -5vh white, 20vw -40vh white, 4vw -22vh white, -22vw 37vh white,
		27vw -43vh white, 20vw 29vh white, 9vw 4vh white, -44vw 3vh white, 34vw 18vh white, 32vw -19vh white, -17vw 14vh white, 23vw -18vh white,
		-20vw 28vh white, 49vw 10vh white, -20vw 21vh white, 7vw -29vh white, 5vw 50vh white, -13vw 35vh white, -20vw -45vh white, -32vw 15vh white,
		-5vw 8vh white, 1vw -8vh white, -3vw -7vh white, -28vw -1vh white, -28vw 11vh white, 44vw -40vh white, -41vw 31vh white, -40vw -48vh white,
		-45vw 4vh white, -36vw -5vh white, 9vw 20vh white, 17vw -5vh white, 29vw -7vh white, -2vw -14vh white, 46vw -4vh white, 24vw -1vh white,
		16vw 45vh white, -7vw 38vh white, 16vw -43vh white, 15vw 37vh white, 23vw -10vh white, -31vw 35vh white, 22vw -13vh white, 40vw -25vh white,
		32vw 35vh white, 32vw 5vh white, -29vw 48vh white, 39vw 6vh white, -36vw -3vh white, 50vw -21vh white, -10vw 25vh white, 33vw -40vh white,
		11vw -15vh white, 47vw 5vh white, 0vw -42vh white;
	-webkit-animation-delay: 2s;
	animation-delay: 2s;
}

.stars-2::after {
	box-shadow: -38vw -23vh white, -39vw 23vh white, -10vw 45vh white, -31vw -11vh white, -25vw 33vh white, -42vw 4vh white, 17vw -20vh white,
		22vw -6vh white, 42vw -1vh white, -5vw -43vh white, 4vw 47vh white, 4vw 46vh white, 35vw -20vh white, 39vw 4vh white, -12vw 31vh white,
		2vw 7vh white, -40vw -21vh white, 27vw -8vh white, 39vw -19vh white, 25vw 38vh white, -44vw -9vh white, 5vw -3vh white, 11vw 38vh white,
		-41vw 22vh white, 8vw -8vh white, -16vw 34vh white, -25vw -3vh white, 22vw -42vh white, 37vw 7vh white, 5vw 39vh white, -47vw 20vh white,
		20vw -12vh white, 5vw 35vh white, -22vw 13vh white, -5vw -16vh white, -5vw 30vh white, -49vw 36vh white, -39vw -6vh white, 18vw 39vh white,
		49vw -8vh white, -18vw 27vh white, 24vw -48vh white, 18vw -40vh white, -49vw 20vh white, -49vw 6vh white, -46vw -11vh white, -47vw 36vh white,
		37vw -14vh white, -15vw -44vh white, -18vw 34vh white, -17vw 12vh white, -13vw 27vh white, 14vw -32vh white, -25vw -42vh white,
		-16vw -45vh white, -41vw 7vh white, 45vw -23vh white, -47vw -35vh white, -12vw 39vh white, -40vw -4vh white, -9vw -1vh white,
		-43vw -29vh white, 38vw 4vh white, -5vw 36vh white, -31vw -21vh white, 45vw 24vh white, -12vw 35vh white, -35vw -26vh white, -9vw -3vh white,
		1vw 37vh white, -11vw 15vh white, -38vw -45vh white, 8vw -23vh white, 42vw -17vh white, -42vw -39vh white, 28vw -48vh white, 7vw 25vh white,
		-26vw 36vh white, 42vw -1vh white, -47vw 22vh white, -37vw -36vh white;
	-webkit-animation-delay: 3s;
	animation-delay: 3s;
}

.logo-container {
	min-height: 100vh;
	perspective: 20vw;
}

.logo {
	transform: translateZ(4vw);
	transform-style: preserve-3d;
	-webkit-animation: logo-appear-animation 3s cubic-bezier(0.65, 0, 0.35, 1);
	animation: logo-appear-animation 3s cubic-bezier(0.65, 0, 0.35, 1);
}
@media (min-width: 991px) {
	.logo {
		transform: rotateX(calc(var(--mouseY) * 2deg)) rotateY(calc(var(--mouseX) * 2deg)) translateZ(4vw);
	}
}
.logo .text {
	position: relative;
	color: white;
	font-size: 7vw;
	font-style: italic;
	line-height: 1em;
	transform: rotate(-5deg);
	transform-style: preserve-3d;
}
@media (min-width: 991px) {
	.logo .text {
		font-size: 12vh;
	}
}
.logo .text::before {
	content: '';
	display: block;
	background: #1e0aaa;
	background: radial-gradient(ellipse at 10% 10%, #f000be 0%, #1e0aaa 80%, #1e0aaa 100%);
	position: absolute;
	top: -10%;
	right: -30%;
	bottom: -10%;
	left: -20%;
	-webkit-clip-path: polygon(50% 0, 10% 40%, 100% 100%);
	clip-path: polygon(50% 0, 10% 40%, 100% 100%);
	z-index: -1;
	-webkit-animation: logo-background-animation 3s cubic-bezier(0.55, 0, 0.35, 1);
	animation: logo-background-animation 3s cubic-bezier(0.55, 0, 0.35, 1);
}
.logo .text .line {
	-webkit-animation: logo-text-animation 4s linear;
	animation: logo-text-animation 4s linear;
}
.logo .text .line-1,
.logo .text .line-2 {
	font-size: 1.5em;
	text-transform: uppercase;
}
.logo .text .line-1 {
	position: relative;
	right: -5%;
	font-family: 'Londrina Outline', var(--font-fallback);
	text-shadow: 0 0 5px #f000be, 0 0 5px #f000be, 0 0 15px #f000be, 0 0 15px #f000be, 0 0 15px #f000be, 0 0 20px #f000be,
		-4px 4px 3px rgba(0, 0, 0, 0.5);
	transform: translateZ(1vw);
	-webkit-animation: logo-text-animation 3s linear;
	animation: logo-text-animation 3s linear;
}
.logo .text .line-2 {
	position: relative;
	right: -10%;
	padding: 1%;
	font-family: 'Londrina Solid', var(--font-fallback);
	background: linear-gradient(5deg, white 10%, #1e0aaa 30%, #141414 50%, white 51%, #1e0aaa 75%, #141414 90%);
	background-clip: text;
	-webkit-background-clip: text;
	-webkit-text-fill-color: transparent;
	transform: translateZ(3vw);
	-webkit-animation: logo-text-animation 3.5s linear;
	animation: logo-text-animation 3.5s linear;
}
@media (min-width: 991px) {
	.logo .text .line-2 {
		-webkit-text-fill-color: rgba(255, 255, 255, calc((var(--mouseX) * var(--mouseY)) / 50));
		text-shadow: 0 0 0.5em rgba(255, 255, 255, calc((var(--mouseX) * var(--mouseY)) / 50));
		background: linear-gradient(
			5deg,
			white 10%,
			#1e0aaa 30%,
			#141414 calc(50% + (var(--mouseY) * -2%)),
			white calc(51% + (var(--mouseY) * -2%)),
			#1e0aaa 75%,
			#141414 90%
		);
		background-clip: text;
		-webkit-background-clip: text;
	}
}
.firefox .logo .text .line-2 {
	background-clip: initial;
	-webkit-text-fill-color: transparent;
	color: white;
	text-shadow: initial;
	-webkit-background-clip: text;
	transform: none;
}
.logo .text .line-3 {
	position: relative;
	top: -10px;
	right: -40%;
	font-family: 'Mr Dafoe', serif;
	color: #f000be;
	z-index: -1;
	transform: translateZ(2vw);
	-webkit-animation: logo-text-animation 4s linear;
	animation: logo-text-animation 4s linear;
}
.logo .text .line-3::first-letter {
	font-size: 1.2em;
}
.logo .text .line-3::before {
	display: block;
	content: '';
	position: absolute;
	right: 10%;
	bottom: 0;
	width: 60%;
	height: 5%;
	background: #f000be;
	-webkit-clip-path: ellipse(50% 30% at 50% 50%);
	clip-path: ellipse(50% 30% at 50% 50%);
}

.sunset {
	min-height: 120vh;
	flex-direction: column;
	justify-content: space-between;
	background: linear-gradient(to bottom, transparent, #141414 15%, #14145a);
	overflow: hidden;
}

.introduction {
	margin: 200px 5vw 50px;
	text-align: center;
	font-family: 'Press Start 2P', monospace;
	z-index: 1;
}
.introduction .name {
	margin-bottom: 50px;
	font-family: 'Londrina Outline', var(--font-fallback);
	font-size: 3em;
	font-style: italic;
	color: white;
	text-shadow: 0 0 5px orange, 0 0 5px orange, 0 0 15px orange, 0 0 15px orange, 0 0 15px orange, 0 0 20px orange, -4px 4px 3px rgba(0, 0, 0, 0.5);
	-webkit-animation: text-neon-glitch 5s linear alternate infinite;
	animation: text-neon-glitch 5s linear alternate infinite;
}
@media (min-width: 991px) {
	.introduction .name {
		font-size: 5em;
	}
}
.introduction .text {
	max-width: 600px;
	margin: 0 auto 50px;
	line-height: 1.5;
	color: #82ffff;
}

.sun-container {
	position: relative;
}

.sun {
	display: block;
	position: relative;
	transform: translateX(-50%);
	left: 50%;
	width: 100vw;
	height: 85vw;
	-webkit-mask-image: repeating-linear-gradient(to bottom, black 0 30px, transparent 30px 40px);
	-webkit-animation: sun-move-down linear 3s infinite;
	animation: sun-move-down linear 3s infinite;
}
@media (min-width: 768px) {
	.sun {
		width: 600px;
		height: 500px;
	}
}
.sun::before {
	display: block;
	content: '';
	position: absolute;
	width: 100%;
	padding-bottom: 100%;
	background-size: 100% 120%;
	background-image: linear-gradient(to bottom, yellow 20%, #f000be 70%, #82ffff 85%);
	border-radius: 50%;
	-webkit-animation: sun-colors 6s linear infinite, sun-move-up linear 3s infinite;
	animation: sun-colors 6s linear infinite, sun-move-up linear 3s infinite;
}

.palm {
	position: absolute;
	bottom: 0;
	opacity: 0.5;
	background: black;
	transform-origin: center bottom;
}
.palm.little {
	left: calc(50% - 45vw);
	width: 50vw;
	height: 50vw;
	-webkit-clip-path: polygon(
		51.68% 100%,
		53.67% 78.98%,
		50.68% 60.34%,
		44.64% 47.96%,
		32.31% 51.65%,
		24.29% 67.58%,
		24.97% 54.53%,
		28.28% 45.52%,
		34.72% 41.22%,
		26.77% 43.41%,
		19.15% 48.81%,
		12.69% 58.22%,
		14.27% 42.12%,
		22.07% 34%,
		28.95% 29.08%,
		21.96% 30.94%,
		21.46% 23.14%,
		19.68% 31.5%,
		12.61% 35.05%,
		2.55% 42.82%,
		9.92% 24.02%,
		19.4% 17.02%,
		32.01% 15.83%,
		39.41% 18.88%,
		35.82% 11.64%,
		21.77% 8.11%,
		34.82% 6.71%,
		43.57% 9.01%,
		50.71% 17.57%,
		59.18% 15.22%,
		69.29% 15.86%,
		77.46% 24.81%,
		65.64% 21.63%,
		57.54% 24.89%,
		68.82% 26.67%,
		77.55% 34.43%,
		83.18% 50.75%,
		71.6% 40.67%,
		61.52% 38.71%,
		69.14% 45.18%,
		72.25% 58.42%,
		62.61% 48.85%,
		60.27% 43.79%,
		59.7% 48.06%,
		54.16% 46.69%,
		61.1% 62.31%,
		64.68% 79.41%,
		64.28% 100%
	);
	clip-path: polygon(
		51.68% 100%,
		53.67% 78.98%,
		50.68% 60.34%,
		44.64% 47.96%,
		32.31% 51.65%,
		24.29% 67.58%,
		24.97% 54.53%,
		28.28% 45.52%,
		34.72% 41.22%,
		26.77% 43.41%,
		19.15% 48.81%,
		12.69% 58.22%,
		14.27% 42.12%,
		22.07% 34%,
		28.95% 29.08%,
		21.96% 30.94%,
		21.46% 23.14%,
		19.68% 31.5%,
		12.61% 35.05%,
		2.55% 42.82%,
		9.92% 24.02%,
		19.4% 17.02%,
		32.01% 15.83%,
		39.41% 18.88%,
		35.82% 11.64%,
		21.77% 8.11%,
		34.82% 6.71%,
		43.57% 9.01%,
		50.71% 17.57%,
		59.18% 15.22%,
		69.29% 15.86%,
		77.46% 24.81%,
		65.64% 21.63%,
		57.54% 24.89%,
		68.82% 26.67%,
		77.55% 34.43%,
		83.18% 50.75%,
		71.6% 40.67%,
		61.52% 38.71%,
		69.14% 45.18%,
		72.25% 58.42%,
		62.61% 48.85%,
		60.27% 43.79%,
		59.7% 48.06%,
		54.16% 46.69%,
		61.1% 62.31%,
		64.68% 79.41%,
		64.28% 100%
	);
	-webkit-animation: palm-little-move 5s 1s ease-in-out infinite alternate;
	animation: palm-little-move 5s 1s ease-in-out infinite alternate;
}
@media (min-width: 768px) {
	.palm.little {
		left: calc(50% - 280px);
		width: 300px;
		height: 300px;
	}
}
.palm.big {
	right: calc(50% - 45vw);
	width: 53vw;
	height: 75vw;
	-webkit-animation: palm-big-move 5s ease-in-out infinite alternate;
	animation: palm-big-move 5s ease-in-out infinite alternate;
}
@media (min-width: 768px) {
	.palm.big {
		right: calc(50% - 260px);
		width: 320px;
		height: 470px;
	}
}

.list {
	text-align: center;
	font-family: 'Press Start 2P', monospace;
}
.list ul {
	margin: 0;
	padding: 0;
}
.list.horizontal li {
	display: inline-block;
	list-style: none;
}
.list.vertical li {
	display: list-item;
	list-style: none;
}
.list li a {
	padding: 10px;
	font-size: 0.8em;
	color: white;
	text-decoration: none;
	opacity: 0.3;
	transition: none;
}
.list li a:hover {
	opacity: 1;
	-webkit-animation: text-glitch calc(var(--random1) * 1s) calc(var(--random2) * 2) steps(3, start);
	animation: text-glitch calc(var(--random1) * 1s) calc(var(--random2) * 2) steps(3, start);
}
